<template>
  <div class="block">
    <div class="d-lg-flex">
      <div class="menu">
        <div v-for="one in arr1">
          <section
            @click="setOne(one.id)"
            :class="oneID == one.id ? 'one-cur' : 'one'"
            v-if="one.nickname"
          >
            {{ one.nickname }}
          </section>
          <div v-for="two in one.subitem">
            <section
              :class="twoID == two.id ? 'two-cur' : 'two'"
              v-if="two.pid == oneID && two.pid == one.id"
              @click="setTwo(two.id)"
            >
              {{ two.nickname }}
            </section>
            <div v-for="three in two.bottomitem">
              <section
                @click="setTherr(three.id)"
                v-if="three.pid == twoID"
                :class="threeID == three.id ? 'three-cur' : 'three'"
              >
                {{ three.nickname }}
              </section>
            </div>
          </div>
        </div>
      </div>

      <div class="product col-lg-9">
        <div class="search">
          <div class="path">
            <template v-for="(item, index) in pathArr">
              <a
                class="a-hover"
                href="javascript:;"
                @click="pathID(item.id, index)"
                v-if="pathArr[0].nickname != 'search'"
                >{{ item.nickname }}</a
              >
              <!-- v-if="index != pathArr.length - 1" -->
              <span
                style="margin: 0 0.25rem"
                v-if="index != pathArr.length - 1"
              >
                ->
              </span>

              <span
                v-if="
                  index == pathArr.length - 1 && pathArr[0].nickname == 'search'
                "
                >{{ item.nickname }}</span
              >
            </template>
          </div>

          <div v-if="search_show">
            <div class="search-title">Advanced Search</div>
            <div class="search-for">
              <section>Search For:</section>
              <div class="search-input">
                <input type="text" v-model="form.title" />
                <button @click="search">search</button>
                <p @click="reset">Clear Search</p>
              </div>
            </div>
            <div class="search-filters">
              <section>Filters:</section>
              <div class="search-option d-lg-flex">
                <div class="option-title">Categort:</div>
                <div class="option-cur">
                  <div class="cur-item" @click="showLi">
                    <p>{{ curOption }}</p>
                    <img src="~assets/images/icon/loginHover.png" alt="" />
                  </div>
                  <ul v-if="curLi">
                    <li v-for="item in arr1">
                      <p @click="getItem(item)">{{ item.nickname }}</p>
                      <p
                        style="padding-left: 0.25rem"
                        @click="getItem(i)"
                        v-for="i in item.subitem"
                      >
                        {{ i.nickname }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="options-right">
                  <div class="price">
                    <p>Min Price:</p>
                    <input type="text" v-model="form.minPrice" />
                  </div>
                  <div class="price">
                    <p>Max Price:</p>
                    <input type="text" v-model="form.maxPrice" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="catalogs" v-if="catalogs">
          <p>
            <span @click="clickCatalogs">Click Here</span> To Shop Our Full
            Digital Catalogs! Shop entire catalogs
          </p>
          <section>
            with the click of a button! Search by SKU, page number or category.
          </section>
        </div>

        <div>
          <div v-if="type">
            <class
              :classArr="classArr"
              v-if="classStatus"
              @getGoods="getGoods"
            />
            <goods
              ref="goods"
              :goods="goods"
              v-if="goodsStatus"
              @goodsDetail="goodsDetail"
              @fatherMethod="fatherMethod"
              :product="product"
              :money="money"
              :count="count"
              :min_num="min_num"
              @DetailMoney="DetailMoney"
              @subtract="subtract"
              @add="add"
              @Iadd="Iadd"
            />
            <page
              ref="pages"
              :ofPages="ofPages"
              :num="pageSize"
              @change_page_num="get_page_num"
              @pageActive="pageActive"
            />
          </div>
          <div v-if="detail">
            <detail
              :product="product"
              @previous="previous"
              @next="next"
              @DetailMoney="DetailMoney"
              :money="money"
              :count="count"
              :min_num="min_num"
              @subtract="subtract"
              @add="add"
              @Iadd="Iadd"
            />
            <!-- <div class="recommend-title">Related Items</div>
            <goods :goods="recommend" />
            <div class="recommend-title">Recent Viewed Items</div>
            <goods :goods="recommend" />
            <div class="recommend-title">Custormers Also Bought</div>
            <goods :goods="recommend" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Page from "./childcomponents/MainPager";
import Goods from "./childcomponents/Goods";
import Detail from "./childcomponents/Details";
import Class from "./childcomponents/Class";
import Cookie from "cookie_js";
import {
  getType,
  getCategoryByPage,
  getProductsByPage,
  getProductDetail,
  getProductQuickview,
  getSiteinfoByName,
} from "network/type";
export default {
  name: "specialT",
  components: { Page, Goods, Detail, Class },
  data() {
    return {
      arr1: [],
      getArr: [],
      oneID: "",
      twoID: "",
      threeID: "",
      allArr: [], //初始化数组 供PathArr使用
      pathArr: [], //路径渲染  记录进来的路径    可返回
      num: 24,
      ofPages: 0,
      id: 0, //产品id用此id请求产品；
      curLi: false,
      curOption: "All", //进来未改变时
      form: {
        title: "",
        cid: "",
        minPrice: "",
        maxPrice: "",
      },
      pageNumber: 1,
      pageSize: 24,
      goods: [],
      classStatus: false,
      goodsStatus: true,
      classArr: [],
      product: {},
      money: "0",
      recommend: [],
      type: true,
      detail: false,
      search_show: true,
      count: 0,
      min_num: 0,
      catalogs: false,
    };
  },

  created() {
    this.form.title = this.$route.query.text;
    this.id = this.$route.params.id;
    this.getType();
    this.getType1();

    if (this.$route.params.id == 0) {
      this.search();
    } else {
      // request
      this.request(this.$route.params.id, 1, 24);
      this.route(this.$route.params.id);
    }
  },

  watch: {
    getArr: function () {
      this.loader();
    },
    $route: function (to, from) {
      this.$router.go(0);
    },
  },

  methods: {
    /*产品请求 */
    search() {
      this.pageNumber = 1;
      this.goods = [];
      getProductsByPage(
        this.form.title,
        this.form.cid,
        this.form.minPrice,
        this.form.maxPrice,
        this.pageNumber,
        this.pageSize
      ).then((res) => {
        if (res.data.code == 0) {
          this.goods = [];
          this.ofPages = 0;
        } else if (res.data.code == 1) {
          this.ofPages = res.data.data.page_total;
          this.goods = res.data.data.product_list;
        }
      });
    },
    /*分类 */
    request(cid, pageNumber, pageSize) {
      // if()

      // if (this.pathArr.length == 0) {
      //   this.setOne(cid);
      // }
      getCategoryByPage(cid, pageNumber, pageSize).then((res) => {
        this.id = cid;
        if (res.data.code == 1) {
          this.ofPages = res.data.data.page_total;
          this.classArr = res.data.data.category_list;
          this.classStatus = true;
          this.goodsStatus = false;
        } else if (res.data.code == 0) {
          /*没有底层后*/
          this.form.cid = cid;
          this.ofPages = 0;
          this.pageNumber = 0;
          this.search();
          this.classStatus = false;
          this.goodsStatus = true;
        }
      });
    },
    /*点击后  详情钱的变化*/
    DetailMoney(item) {
      this.money = item.price;
      // this.min_num = Number(item.min_num);
      this.count = Number(item.min_num);
    },

    /*点击请求路径与产品*/
    getGoods(id) {
      this.id = id;
      if (this.pathArr.length == 0) {
        this.setOne(id);
      } else if (this.pathArr.length == 1) {
        this.setTwo(id);
      } else if (this.pathArr.length == 2) {
        this.setTherr(id);
      }
    },

    //电子目录
    clickCatalogs() {
      if (Cookie.get("token")) {
        getSiteinfoByName("e_catalog").then((res) => {
          if (res.data.code == 1) {
            let url = res.data.data;
            window.open(url);
          }
        });
      } else {
        this.$router.push("/signIn");
      }
    },
    /*详情页请求上一页下一页*/
    previous(id) {
      // getProductDetail(id, sid).then((res) => {
      //   this.product = res.data.data;
      // });

      getProductDetail(id, this.product.product.sid).then((res) => {
        if (res.data.code == 1) {
          this.product = res.data.data;
        }
        if (res.data.data.product) {
          this.money = Number(res.data.data.product.options[0].price);
          this.count = Number(res.data.data.product.options[0].min_num);
          this.min_num = Number(res.data.data.product.options[0].min_num);
        }
      });
    },
    next(id) {
      // getProductDetail(id, sid).then((res) => {
      //   this.product = res.data.data;
      // });
      getProductDetail(id, this.product.product.sid).then((res) => {
        if (res.data.code == 1) {
          this.product = res.data.data;
        }
        if (res.data.data.product) {
          this.money = Number(res.data.data.product.options[0].price);
          this.count = Number(res.data.data.product.options[0].min_num);
          this.min_num = Number(res.data.data.product.options[0].min_num);
        }
      });
    },

    subtract() {
      this.count--;
      if (this.product.options[2].min_num <= this.count) {
        this.$refs.goods.opIndex = 2;
        this.money = Number(this.product.options[2].price).toFixed(2);
      } else if (this.product.options[1].min_num <= this.count) {
        this.$refs.goods.opIndex = 1;
        this.money = Number(this.product.options[1].price).toFixed(2);
      } else {
        this.$refs.goods.opIndex = 0;
        this.money = Number(this.product.options[0].price).toFixed(2);
      }
    },
    add() {
      this.count++;

      if (this.product.options[2].min_num <= this.count) {
        this.$refs.goods.opIndex = 2;
        this.money = Number(this.product.options[2].price).toFixed(2);
      } else if (this.product.options[1].min_num <= this.count) {
        this.$refs.goods.opIndex = 1;
        this.money = Number(this.product.options[1].price).toFixed(2);
      } else {
        this.$refs.goods.opIndex = 0;
        this.money = Number(this.product.options[0].price).toFixed(2);
      }
    },

    Iadd(value) {
      if (Number(value) < this.min_num) {
        this.$notify({
          message: "Buy at least " + this.min_num,
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
      } else {
        this.count = Number(value);
        if (this.product.options[2].min_num <= this.count) {
          this.$refs.goods.opIndex = 2;
          this.money = Number(this.product.options[2].price).toFixed(2);
        } else if (this.product.options[1].min_num <= this.count) {
          this.$refs.goods.opIndex = 1;
          this.money = Number(this.product.options[1].price).toFixed(2);
        } else {
          this.$refs.goods.opIndex = 0;
          this.money = Number(this.product.options[0].price).toFixed(2);
        }
      }
    },

    /*请求产品详情*/
    goodsDetail(item) {
      this.product = {};
      getProductQuickview(item.id, item.sid).then((res) => {
        if (res.data.code == 1) {
          this.product = res.data.data;
        }
        if (res.data.data.options) {
          this.money = res.data.data.options[0].price;
          this.count = Number(res.data.data.options[0].min_num);
          this.min_num = Number(res.data.data.options[0].min_num);
        }
      });

      // getProductDetail(id).then((res) => {
      //   this.product = res.data.data;
      //   if (res.data.data.product.options) {
      //     this.money = Number(res.data.data.product.options[0].price);
      //   }
      // });
    },

    get_page_num(data) {
      this.pageSize = data;
      this.$refs.pages.page = 1;
      this.search();
      this.getproduct();
    },

    pageActive(data) {
      this.pageNumber = data;
      if (this.id == 0) {
        this.form.cid = "";
        this.getproduct();
      } else {
        for (let i = 0; i < this.getArr.length; i++) {
          if (this.getArr[i].id == this.id) {
            if (this.getArr[i].haschild == 1) {
              this.request(this.id, data, this.pageSize);
            } else {
              this.form.cid = this.id;
              this.getproduct();
            }
          }
        }
      }
    },

    getproduct() {
      getProductsByPage(
        this.form.title,
        this.form.cid,
        this.form.minPrice,
        this.form.maxPrice,
        this.pageNumber,
        this.pageSize
      ).then((res) => {
        if (res.data.code == 0) {
          this.goods = [];
          this.ofPages = 0;
          this.pageNumber = 0;
        } else if (res.data.code == 1) {
          this.ofPages = res.data.data.page_total;
          this.pageNumber = res.data.data.page_total;
          this.goods = res.data.data.product_list;
        }
      });
    },

    fatherMethod(id) {
      this.type = false;
      this.detail = true;
      this.search_show = false;
      this.catalogs = false;
      getProductDetail(id, this.product.sid).then((res) => {
        if (res.data.code == 1) {
          this.product = res.data.data;
        }
        if (res.data.data.product.options) {
          this.money = res.data.data.product.options[0].price;
          this.count = Number(res.data.data.product.options[0].min_num);
          this.min_num = Number(res.data.data.product.options[0].min_num);
        }
      });

      // this.goodsDetail(id);
    },
    //点击路径的时候  重新定路径  改变左边显示列表   重新请求产品
    pathID(id, index) {
      if (index == 0) {
        this.setOne(id);
      } else if (index == 1) {
        this.setTwo(id);
      }
    },

    //选择分类
    showLi() {
      this.curLi = !this.curLi;
    },
    getItem(obj) {
      this.curLi = false; //到时候传id  然后去找产品
      if (obj.nickname) {
        this.curOption = obj.nickname;
      }
      this.id = obj.id;
      this.pageNumber = 1;
      this.request(obj.id, this.pageNumber, this.pageSize);
    },
    //一级分类
    setOne(id) {
      if (id == 2) {
        this.catalogs = true;
      } else {
        this.catalogs = false;
      }
      this.detail = false;
      this.type = true;
      this.oneID = id;
      this.twoID = "";
      this.threeID = "";
      this.id = id;
      this.search_show = false;
      this.form.title = "";
      this.route(id);
      this.pageNumber = 1;
      this.request(id, this.pageNumber, this.pageSize);
    },
    //二级分类
    setTwo(id) {
      this.catalogs = false;
      this.detail = false;
      this.type = true;
      this.twoID = id;
      this.threeID = "";
      this.id = id;
      this.search_show = false;
      this.form.title = "";
      this.route(id);
      this.pageNumber = 1;
      this.request(id, this.pageNumber, this.pageSize);
    },
    //三级分类
    setTherr(id) {
      this.catalogs = false;
      this.detail = false;
      this.type = true;
      this.search_show = false;
      this.form.title = "";
      this.threeID = id;
      this.id = id;
      this.route(id);
      this.pageNumber = 1;
      this.request(id, this.pageNumber, this.pageSize);
    },

    //路径
    route(id) {
      this.pathArr = [];
      let allArr = this.getArr;
      let p1 = "";
      let p2 = "";
      let p3 = "";
      //把点击过了  记录在seach需要的目录
      for (let i = 0; i < allArr.length; i++) {
        if (id == allArr[i].id) {
          p1 = allArr[i];
          this.pathArr.push(p1);
          for (let j = 0; j < allArr.length; j++) {
            if (p1.pid == allArr[j].id) {
              p2 = allArr[j];
              this.pathArr.push(p2);
              for (let x = 0; x < allArr.length; x++) {
                if (p2.pid == allArr[x].id) {
                  p3 = allArr[x];
                  this.pathArr.push(p3);
                }
              }
            }
          }
        }
      }

      this.pathArr.reverse();
    },
    /**/
    getType1() {
      getType().then((res) => {
        let data = res.data.data;
        let arr = [];
        for (let temp in data) {
          if (data[temp].id != 0) {
            arr.push(data[temp]);
          }
        }
        this.getArr = arr;
      });
    },
    /*左边大分类*/
    getType() {
      getType().then((res) => {
        var data = res.data.data;
        this.getArr = res.data.data;
        delete data[0];
        let pcategory = [];
        for (let temp in data) {
          if (data[temp].pid == 0) {
            pcategory.push(data[temp]);
            delete data[temp];
          }
        }
        let subitem = [];
        for (let i = 0; i < pcategory.length; i++) {
          for (let temp in data) {
            if (data[temp].pid == pcategory[i].id) {
              subitem.push(data[temp]);
              delete data[temp];
            }
          }
          subitem = subitem.sort(this.compare("weigh"));
          pcategory[i].subitem = subitem;
          subitem = [];
        }
        let bottomitem = [];
        for (let i = 0; i < pcategory.length; i++) {
          for (let x = 0; x < pcategory[i].subitem.length; x++) {
            for (let temp in data) {
              if (data[temp].pid == pcategory[i].subitem[x].id) {
                bottomitem.push(data[temp]);
                delete data[temp];
              }
            }
            pcategory[i].subitem[x].bottomitem = bottomitem;
            bottomitem = [];
          }
        }
        this.arr1 = pcategory;
      });
    },

    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },

    /*页面载入*/
    loader() {
      var _this = this;
      if (this.id == 0) {
        let a = [{ nickname: "search" }];
        this.pathArr = a;
      } else {
        _this.$nextTick(function () {
          if (this.pathArr.length == 1) {
            this.oneID = this.id;
            this.twoID = "";
            this.threeID = "";
          } else if ((this.pathArr.length = 2)) {
            for (let i = 0; i < this.getArr.length; i++) {
              if (this.getArr[i].id == this.id) {
                for (let x = 0; x < this.getArr.length; x++)
                  if (this.getArr[i].pid == this.getArr[x].id) {
                    this.oneID = this.getArr[x].id;
                    this.twoID = this.id;
                    this.threeID = "";
                  }
              }
            }
            this.route(this.id);
          } else {
            this.route(this.id);
            return;
          }
        });
      }
    },
    reset() {
      this.id = 0;
      this.$router.go(0);
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
ul {
  margin: 0;
}
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.block {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.menu {
  padding: 0.5rem;
  background: #ff7f00;
  font-size: 0.4rem;
  color: #fff;
  height: 100%;
}
.menu li {
  list-style: none;
}
.one {
  cursor: pointer;
}
.one-cur {
  font-weight: 600;
  cursor: pointer;
}
.two {
  margin-left: 0.25rem;
  cursor: pointer;
}
.two-cur {
  margin-left: 0.25rem;
  font-weight: 600;
  cursor: pointer;
}
.three {
  margin-left: 0.5rem;
  cursor: pointer;
}
.three-cur {
  margin-left: 0.5rem;
  font-weight: 600;
  cursor: pointer;
}

.cur {
  font-weight: 800;
}
.hover:hover {
  text-decoration: underline;
  cursor: pointer;
}
.sherch {
  padding: 0.5rem;
}
.path {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 0.4rem;
  margin-top: 0.5rem;
}
.a-hover {
  color: #ff7f00;
}
.a-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}
.search-title {
  color: #ff7f00;
  font-size: 0.45rem;
  margin-top: 0.25rem;
}
.search-for section {
  color: #ff7f00;
  font-size: 0.4rem;
  margin-top: 0.25rem;
}
.search-input {
  display: flex;
  font-size: 0.4rem;
  margin-top: 0.25rem;
}
.search-input input {
  padding: 0 0.5rem;
  border: 1px solid #737373;
  border-radius: 0.2rem;
  font-size: 0.3rem;
}
.search-input button {
  color: #ff7f00;
  border: 1px solid #ff7f00;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  margin: 0 0.5rem;
}
.search-input p {
  color: #ff7f00;
  text-decoration: underline;
  cursor: pointer;
}
.search-filters {
  font-size: 0.4rem;
  border-bottom: 1px solid #edeede;
  margin-top: 0.25rem;
}
.search-filters section {
  color: #ff7f00;
  font-size: 0.45rem;
  margin-top: 0.25rem;
}
.cur-item p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 4rem;
}

.cur-item {
  width: 4rem;
  border: 1px solid #737373;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-right: 0.25rem;
}
.cur-item img {
  width: 0.3rem;
  height: 0.25rem;
}
.option-cur {
  position: relative;
  margin-bottom: 0.5rem;
}
.option-cur ul {
  background: #fff;
  position: absolute;
  width: 4rem;
  border-radius: 0.25rem;
  height: 5rem;
  overflow-x: hidden;
  z-index: 9;
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  font-size: 0.4rem;
}
.option-cur ul li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333333;
  padding: 0 0.25rem;
}
.option-cur ul li p:hover {
  color: #ff7f00;
  background: #ffecd9;
}
.option-title {
  color: #4c4c4c;
  font-size: 0.35rem;
  margin-right: 0.25rem;
}
.price {
  display: flex;
  margin-right: 0.5rem;
}
.price input {
  border: 1px solid #737373;
  width: 2rem;
  margin-left: 0.25rem;
  border-radius: 0.15rem;
}
.options-right {
  display: flex;
  font-size: 0.4rem;
  color: #4c4c4c;
  margin-bottom: 0.5rem;
}
.recommend-title {
  color: #ff7f00;
  font-size: 0.5rem;
}
.catalogs {
  text-align: center;
}
.catalogs p {
  margin-top: 0.25rem;
  color: #75072e;
  font-size: 0.45rem;
}
.catalogs p span {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.catalogs section {
  margin-top: 0.25rem;
  font-size: 0.35rem;
  color: #535051;
}

@media screen and (max-width: 768px) {
  .menu {
    font-size: 0.7rem;
  }
}
</style>