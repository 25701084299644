<template>
  <div class="goods d-flex">
    <div
      class="item col-6 col-lg-3 col-md-3"
      @mouseenter="show(index)"
      @mouseleave="hidden"
      v-for="(item, index) in goods"
      @click.prevent="goDetail(item.id)"
      v-if="goods.length != 0 && goods != {}"
    >
      <!-- @click.prevent="setPage(item.id)" -->

      <div
        v-if="index == hoverI"
        @click.stop="goodsDetail(item)"
        class="hover-button d-none d-lg-block"
      >
        Quick View
      </div>
      <div class="item-img">
        <img :src="item.thumb" alt="" />
      </div>
      <div class="item-title">
        {{ item.description }}
      </div>
      <div class="item-type">
        {{ item.item }}
      </div>
    </div>
    <div v-if="goods.length == 0" class="none">
      Your search did not result in any matches
    </div>

    <div
      class="show d-none d-lg-block"
      v-if="detail && Object.keys(product).length != 0"
    >
      <div class="show-top">
        <div class="top-title">
          Quick View:Res Holiday Snowman Shelf Sitter w/LED 4 Asst
        </div>
        <div class="top-close" @click="close">close</div>
      </div>
      <div class="info">
        <div class="img col-4">
          <img :src="product.thumb" alt="" />
        </div>
        <div class="infomation col-8">
          <span class="isnew" v-if="product.isnew == 1">NEW</span>
          <div class="info-title">
            {{ product.description }}
          </div>
          <div class="info-type">
            <p>{{ product.size }}</p>
            <p>{{ product.item }}</p>
            <p>UPC:{{ product.barcode }}</p>
          </div>
          <div class="info-money" v-if="product.options">
            <p>${{ money }}</p>
            <p>Ship date: {{ product.ship_date }}</p>
          </div>
          <div class="info-pick" v-if="product.options">
            <p
              v-for="(item, index) in product.options"
              :style="
                index == opIndex ? 'color:#ff7f00;border:1px solid #ff7f00' : ''
              "
              @click="option(item, index)"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="info-num" v-if="product.options">
            <p>Quantity:</p>
            <section>
              <button @click="subtract">-</button>
              <span @click="open(product)">
                <input v-model="count" type="text" disabled />
              </span>
              <button @click="add">+</button>
            </section>
            <div class="info-add" @click="cart">Add to Cart</div>
          </div>
          <div class="detail" @click="goDetail(product.id)">
            View full details
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addToCart } from "network/addCart";
import { user } from "network/login";
export default {
  data() {
    return {
      hoverI: -1,
      detail: false,
      opIndex: 0,
    };
  },

  props: {
    goods: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      default: () => {},
    },
    money: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    min_num: {
      type: Number,
      default: 0,
    },
  },
  created() {},

  methods: {
    open(p) {
      this.$prompt("Enter the purchase quantity", {
        confirmButtonText: "yes",
        cancelButtonText: "no",
        inputErrorMessage: "Enter the correct quantity",
        inputType: "number",
      })
        .then(({ value }) => {
          this.$emit("Iadd", value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel the input",
          });
        });
    },

    //该选项时钱  选择框 变化
    option(item, i) {
      this.opIndex = i;
      this.$emit("DetailMoney", item);
    },

    /**/
    getUser() {
      user().then((res) => {
        if (res.data.code == 1) {
          this.user = res.data.data.user;
        } else {
          return;
        }
      });
    },

    //添加购物车
    cart() {
      this.detail = false;
      let options;
      if (this.opIndex == 0) {
        options = "p_1";
      } else if (this.opIndex == 1) {
        options = "p_2";
      } else if (this.opIndex == 2) {
        options = "p_3";
      }

      this.opIndex = 0;
      // let count = this.count;
      addToCart(options, this.product.id, this.count).then((res) => {
        if (res.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "erreo",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    subtract() {
      if (this.count <= this.min_num) {
        this.$notify({
          message: "Buy at least " + this.min_num,
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
      } else {
        this.$emit("subtract");
      }
    },
    add() {
      this.$emit("add");
    },
    show(index) {
      this.hoverI = index;
    },
    hidden() {
      this.hoverI = -1;
    },
    goodsDetail(item) {
      this.detail = true;
      this.$emit("goodsDetail", item);
    },
    close() {
      this.detail = false;
    },
    setPage(id) {
      this.detail = false;
      if (this.product != {}) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.$emit("fatherMethod", id);
      }
    },

    goDetail(id) {
      // this.$router.push({
      //   path: "/product",
      //   query: {
      //     id,
      //   },
      // });
      window.open("/product?id=" + id);
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.goods {
  display: flex;
  flex-wrap: wrap;
}
.item {
  margin-top: 0.25rem;
  font-size: 0.3rem;
  cursor: pointer;
}

.item:hover .item-title {
  color: #ff7f00;
  text-decoration: underline;
}
.item-img {
  margin-top: 0.3rem;
  height: 4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.hover-button {
  position: absolute;
  background: #ff7f00;
  top: 25%;
  left: 20%;
  width: 60%;
  text-align: center;
  padding: 0.2rem 0;
  border-radius: 0.1rem;
  color: #fff;
  cursor: pointer;
}
.item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}
.item-title {
  text-align: center;
  color: #4c4c4c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.25rem;
}
.item-type {
  text-align: center;
  color: #737373;
  margin: 0.1rem 0 0.5rem 0;
}
.show {
  position: fixed;
  background: #ff7f00;
  top: 30%;
  width: 30%;
  border-radius: 0.1rem;
  z-index: 99;
}
.show-top {
  font-size: 0.3rem;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.25rem;
  color: #fff;
}
.top-close {
  cursor: pointer;
}
.info {
  background: #fff;
  margin: 0.1rem;
  display: flex;
  font-size: 0.4rem;
  justify-content: space-between;
}
.info-title {
  color: #ff7f00;
  font-size: 0.5rem;
}
.info-type {
  margin-top: 0.25rem;
  color: #737373;
}
.info-money {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  color: #de0e12;
  font-size: 0.6rem;
}
.info-money p:last-child {
  color: #4c4c4c;
  font-size: 0.4rem;
}

.info-pick {
  display: flex;
  flex-wrap: wrap;
}
.info-pick p {
  border: 1px solid #e0e0e0;
  padding: 0.15rem 0.3rem;
  color: #737373;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}

.info-num {
  display: flex;
  margin-top: 0.25rem;
  align-items: center;
}
.info-num p {
  color: #737373;
  margin-right: 0.25rem;
}
.info-num section {
  border: 1px solid #e0e0e0;
  border-radius: 0.1rem;
}
.info-num button {
  padding: 0.1rem 0.25rem;
}

.info-num input {
  padding: 0.1rem 0.25rem;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  width: 1.5rem;
  text-align: center;
}
.info-add {
  padding: 0.1rem 0.25rem;
  background: #ff7f00;
  color: #fff;
  margin-left: 0.25rem;
  border-radius: 0.1rem;
  cursor: pointer;
}
.detail {
  color: #ff7f00;
  text-decoration: underline;
  margin: 1rem 0 0.25rem 0;
  cursor: pointer;
}
.none {
  padding: 0.5rem 0;
  font-size: 0.3rem;
}
.img {
  width: 100%;
  height: 4rem;
  margin-top: 0.5rem;
}
.img img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}
.isnew {
  background-color: skyblue;
  padding: 3px 5px;
  font-weight: 700;
  color: #fff;
  width: 45px;
  border-radius: 5px;
}

@media screen and (max-width: 1800px) {
  .show {
    width: 40%;
  }
}

@media screen and (max-width: 1300px) {
  /*bootstrap 小屏幕*/
  .show {
    width: 50%;
  }
}

@media screen and (max-width: 1100px) {
  .show {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .item-img {
    margin-top: 0.3rem;
    height: 6rem;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}
</style>