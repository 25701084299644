<template>
  <div class="product">
    <div class="d-flex" v-if="Object.keys(product).length != 0">
      <div class="img col-4">
        <img :src="product.product.thumb" alt="" />
      </div>
      <div class="info col-8">
        <div class="path d-flex">
          <p
            @click="previous(product.preProduct)"
            :class="product.preProduct == null ? 'nonecolor' : ''"
          >
            « Previous
          </p>
          <p
            @click="next(product.nextProduct)"
            :class="product.nextProduct == null ? 'nonecolor' : ''"
          >
            Next »
          </p>
        </div>
        <div class="infomation">
          <span class="isnew" v-if="product.product.isnew == 1">NEW</span>
          <div class="info-title">
            {{ product.product.description }}
          </div>
          <div class="info-type">
            <p>{{ product.product.size }}</p>
            <p>{{ product.product.item }}</p>
            <p>UPC:{{ product.product.barcode }}</p>
          </div>

          <div class="info-money" v-if="product.product.options">
            <p>${{ money }}</p>
            <p>Ship date: {{ product.product.ship_date }}</p>
          </div>
          <div class="info-pick" v-if="product.product.options">
            <p
              v-for="(item, index) in product.product.options"
              :style="
                index == opIndex ? 'color:#ff7f00;border:1px solid #ff7f00' : ''
              "
              @click="option(item, index)"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="info-num" v-if="product.product.options">
            <p>Quantity:</p>
            <section>
              <button @click="subtract">-</button>
              <span @click="open">
                <input v-model="count" type="text" disabled />
              </span>
              <button @click="add">+</button>
            </section>
            <div class="info-add" @click="cart">Add to Cart</div>
          </div>
        </div>
      </div>
    </div>
    <div class="big-img">
      <img src="~assets/images/icon/glass.png" alt="" />
      <span @click="img_path">view larger image</span>
    </div>
  </div>
</template>
<script>
import { addToCart } from "network/addCart";
export default {
  data() {
    return {
      opIndex: 0,
    };
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    money: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    min_num: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    open() {
      this.$prompt("Enter the purchase quantity", {
        confirmButtonText: "yes",
        cancelButtonText: "no",
        inputErrorMessage: "Enter the correct quantity",
        inputType: "number",
      })
        .then(({ value }) => {
          this.$emit("Iadd", value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel the input",
          });
        });
    },
    //新页面打开产品大图
    img_path() {
      window.open(this.product.product.thumb);
    },

    //选择分类
    option(item, i) {
      this.opIndex = i;
      this.$emit("DetailMoney", item);
    },

    //添加购物车
    cart() {
      this.detail = false;
      let options;
      if (this.opIndex == 0) {
        options = "p_1";
      } else if (this.opIndex == 1) {
        options = "p_2";
      } else if (this.opIndex == 2) {
        options = "p_3";
      }
      // let count = this.count;
      addToCart(options, this.product.product.id, this.count).then((res) => {
        if (res.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "erreo",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    subtract() {
      if (this.count <= this.min_num) {
        this.$notify({
          message: "Buy at least " + this.min_num,
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
      } else {
        this.$emit("subtract");
      }
    },
    add() {
      this.$emit("add");
    },

    previous(x) {
      if (x) {
        this.$emit("previous", x.id);
      } else {
        return;
      }
    },
    next(x) {
      if (x) {
        this.$emit("next", x.id);
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.product {
  margin-top: 0.25rem;
}
.img {
  width: 100%;
  height: 4rem;
}
.img img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}
.info {
  color: #ff7f00;
  font-size: 0.4rem;
}
.path {
  justify-content: space-between;
}
.path p {
  cursor: pointer;
}
.info-title {
  color: #ff7f00;
  font-size: 0.5rem;
}
.info-type {
  margin-top: 0.25rem;
  color: #737373;
}
.info-money {
  margin-top: 0.25rem;
  color: #de0e12;
  justify-content: space-between;
  font-size: 0.6rem;
  display: flex;
}
.info-money p:last-child {
  color: #4c4c4c;
  font-size: 0.4rem;
}
.info-pick {
  display: flex;
  flex-wrap: wrap;
}
.info-pick p {
  border: 1px solid #e0e0e0;
  padding: 0.15rem 0.3rem;
  color: #737373;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
}
.info-num {
  display: flex;
  margin-top: 0.25rem;
  align-items: center;
}
.info-num p {
  color: #737373;
  margin-right: 0.25rem;
}
.info-num section {
  border: 1px solid #e0e0e0;
  border-radius: 0.1rem;
}
.info-num button {
  padding: 0.1rem 0.25rem;
}

.info-num input {
  padding: 0.1rem 0.25rem;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  width: 1.5rem;
  text-align: center;
}
.info-add {
  padding: 0.1rem 0.25rem;
  background: #ff7f00;
  color: #fff;
  margin-left: 0.25rem;
  border-radius: 0.1rem;
  cursor: pointer;
}
.detail {
  color: #ff7f00;
  text-decoration: underline;
  margin: 1rem 0 0.25rem 0;
  cursor: pointer;
}
.big-img {
  color: #333333;
  font-size: 0.4rem;
  margin-bottom: 1rem;
}
.big-img span {
  margin-left: 0.25rem;
  cursor: pointer;
}
.nonecolor {
  text-decoration: line-through;
}
.isnew {
  background-color: skyblue;
  padding: 3px 5px;
  font-weight: 700;
  color: #fff;
  width: 45px;
  border-radius: 5px;
}
</style>