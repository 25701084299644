<template>
  <div>
    <div class="list d-flex" v-if="classArr.length != 0">
      <div
        class="item col-6 col-lg-3 col-md-3"
        v-for="item in classArr"
        @click="getGoods(item.id)"
      >
        <div class="item-img">
          <img :src="item.image" alt="" />
        </div>
        <div class="item-title">{{ item.nickname }}</div>
      </div>
    </div>
    <div v-if="classArr.length == 0" class="none">
      Your search did not result in any matches
    </div>
  </div>
</template>
<script>
export default {
  props: {
    classArr: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getGoods(id) {
      this.$emit("getGoods", id);
    },
  },
};
</script>
<style scoped>
.list {
  width: 100%;
  flex-wrap: wrap;
}
.item {
  margin-top: 0.25rem;
  font-size: 0.3rem;
  cursor: pointer;
}
.item-img {
  margin-top: 0.3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.item-img img {
  width: 100%;
}
.item-title {
  text-align: center;
  color: #4c4c4c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.25rem;
  color: #ff7f00;
}
.none {
  padding: 0.5rem 0;
  font-size: 0.3rem;
}
@media screen and (max-width: 768px) {
  .item-img {
    margin-top: 0.3rem;
    height: 6rem;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}
</style>